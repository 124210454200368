import './Combat.css';
import '../../../Common/Animations.css'
import '../../../Common/Common.css'
import '../../Layout.css';
import { useLocation, useParams } from 'react-router-dom';
import { Text } from "react-native";
import React, { useState, useEffect } from "react";
import { toTitleCase } from '../../../utilities/toTitleCase';
import { baseApiUrl } from '../../../utilities/baseApiUrl';
import NavigationLinker from '../../../Common/NavigationLinker';
import { getItemFromArray } from '../../../utilities/getItemFromArray';
import { getCurrentPageFromLocation } from '../../../utilities/getCurrentPage';
import { PrintDots } from '../../../utilities/dotprinter';
import { styles } from '../../../utilities/styles';

function CombatManeuvers() {

    const [data, setData] = useState([]);
    const currentPage = getCurrentPageFromLocation(useLocation());
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            loadData();
        }
    }, []);

    const loadData = async () => {
        await fetch(baseApiUrl + 'rules/combat_maneuvers')
            .then(response => response.json())
            .then(data => setData(data))
            .then(setLoading(true));
    }

    console.log(data);

    function renderLowerPage(dataToCheck) {
        if (dataToCheck !== undefined) {
            return (
                <div className="fadeIn">
                    <h2 className='wolfHeader'>{dataToCheck.name}</h2>
                    <Text style={styles.baseText}>{dataToCheck.description}</Text>
                    {renderSubSections(dataToCheck.subsections)}
                </div>
            )
        }
    }

    function renderSubSections(dataToCheck) {
        if (dataToCheck !== undefined) {
            return (
                <div>
                    {dataToCheck.map((item) => (
                        <div>
                            <h3>{item.name}</h3>
                            <Text style={styles.baseText}>{item.description}</Text>
                            <div>{item.usable_by}</div>
                        </div>
                    ))}
                </div>
            )
        }
    }

    function TypesNavigations() {
        return (
            <>
                <div className={`tabMenu contentTab`}>
                    <NavigationLinker to="general_maneuvers" name="General Maneuvers" />
                    <NavigationLinker to="dirty_fighting" name="Dirty Fighting" />
                    <NavigationLinker to="special_maneuvers" name="Special Maneuvers" />
                    <NavigationLinker to="pack_tactics" name="Pack Tactics" />
                </div>
            </>
        )
    }

    function renderContent() {
        if (loading) {
            return (
                <div className='fadeIn'>
                    <h1 className="wolfHeader">{data.name}</h1>
                    <Text style={styles.baseText}>{data.description}</Text>
                    <TypesNavigations />
                    {renderLowerPage(getItemFromArray(currentPage, data.tabs, "id"))}
                </div>
            )
        }
        else {
            return (
                <div className='fadeIn'>

                </div>
            )
        }
    }

    return (
        <section className="mainSection combatRulesSection">
            <div className="contentContainer">
                {renderContent()}
            </div>
        </section>
    );
};

export default CombatManeuvers;