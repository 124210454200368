import './Breeds.css';
import '../../Layout.css';
import { useParams, useLocation, Link } from 'react-router-dom';
import { Text, StyleSheet } from "react-native";
import React, { useState, useEffect } from "react";
import { ArrayToString } from '../../../utilities/arrayToString';
import { baseApiUrl } from '../../../utilities/baseApiUrl';
import { styles } from '../../../utilities/styles';
import NavigationLinker from '../../../Common/NavigationLinker';
import GoBackTo from '../../../utilities/backButtonRoutes';
import { getCurrentPageFromLocation } from '../../../utilities/getCurrentPage';

function Breed() {
    //data state // storage
    const [data, setData] = useState([]);
    const { name } = useParams();
    const currentPage = getCurrentPageFromLocation(useLocation());
    const [loading, setLoading] = useState(false);
    const logo = require('../../../static/icons/breeds/' + name + '.png');

    useEffect(() => {
        if (!loading) {
            loadData();
        }
    }, []);

    const loadData = async () => {
        await fetch(baseApiUrl + 'breeds/' + name)
            .then(response => response.json())
            .then(data => setData(data))
            .then(setLoading(true));
    }

    function renderNicknames(dataToCheck) {
        if (dataToCheck !== undefined) {
            return (
                <div>
                    <h2 className='wolfHeader'>Nicknames</h2>
                    <Text style={styles.baseText}>
                        {ArrayToString(data.nicknames)}
                    </Text>
                </div>
            )
        }
    }

    function renderStartingGifts(dataToCheck) {
        if (dataToCheck !== undefined) {
            return (
                <div>
                    <h2 className='wolfHeader'>Starting Gifts</h2>
                    <Text style={styles.baseText}>
                        {ArrayToString(data.starting_gifts)}
                    </Text>
                </div>
            )
        }
    }

    function renderDeformities(dataToCheck) {
        if (dataToCheck.deformities !== undefined && dataToCheck.name === "Metis") {
            return (
                <div>
                    <h2 className='wolfHeader'>Deformities</h2>
                    <Text style={styles.baseText}>
                        {data.deformities.description}
                    </Text>
                    {renderDeformitiesList(data.deformities.deformities_list)}
                </div>
            )
        }
    }

    function renderDeformitiesList(dataToCheck) {
        if (dataToCheck !== undefined) {
            return (
                <div>
                    {dataToCheck.map((deformity) => (
                        <div key={deformity.name}>
                            <h3 className='wolfHeader'>
                                {deformity.name}
                            </h3>
                            <Text style={styles.baseText}>
                                {deformity.description}
                            </Text>
                        </div>
                    ))}
                </div>
            )
        }
    }

    function renderLupusRestrictions(dataToCheck) {
        if (dataToCheck.restricted_abilities !== undefined && dataToCheck.name === "Lupus") {
            return (
                <div>
                    <h2 className='wolfHeader'>Restricted Abilities</h2>
                    {dataToCheck.restricted_abilities}
                    <h2 className='wolfHeader'>Restricted Skills</h2>
                    <ul className='restrictedList'>
                        {dataToCheck.restricted_skills.map((skill) => (
                            <li key={skill}>
                                <Link to={'/abilities/' + skill.toString().toLowerCase()}>{skill}</Link>
                            </li>
                        ))}
                    </ul>
                    <h2 className='wolfHeader'>Restricted Knowledges</h2>
                    <ul className='restrictedList'>
                        {dataToCheck.restricted_knowledges.map((knowledge) => (
                            <li key={knowledge}>
                                <Link to={'/abilities/' + knowledge.toString().toLowerCase()}>{knowledge}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
            )
        }
    }

    function renderInfo() {
        return (
            <div>
                <div className='topContent'>
                    {renderNicknames(data.nicknames)}
                    <div>
                        <h2 className='wolfHeader'>Initial Gnosis</h2>
                        <Text style={styles.baseText}>
                            {data.gnosis}
                        </Text>
                    </div>
                </div>
                <h3 className='wolfHeader'>Description</h3>
                <Text style={styles.baseText}>
                    {data.description}
                </Text>
            </div>)
    }

    function renderContent() {
        if (loading) {
            return (
                <div className='fadeIn'>
                    <ul className='headerArea'>
                        <li>
                            <GoBackTo />
                        </li>
                        <li>
                            <h1 className='wolfHeader'>{data.name}</h1>
                        </li>
                    </ul>
                    <span className='characterLogoContainer'>
                        <img className='characterLogo' src={logo} />
                    </span>
                    <BreedNavigations />
                    {
                        currentPage === 'info' ? renderInfo() : ""
                    }
                    {
                        currentPage === 'gifts' ? renderStartingGifts(data.starting_gifts) : ""
                    }
                    {
                        currentPage === 'deformities' ? renderDeformities(data) : ""
                    }
                    {
                        currentPage === 'restrictions' ? renderLupusRestrictions(data) : ""
                    }
                </div>
            )
        }
        else {
            return (
                <div className='fadeIn'>

                </div>
            )
        }
    }

    function BreedNavigations() {
        return (
            <>
                <div className={`tabMenu contentTab`}>
                    <NavigationLinker to="info" name="Information" />
                    <NavigationLinker to="gifts" name="Starting Gifts" />
                    {
                        name == 'metis' ? <NavigationLinker to="deformities" name='Deformities' /> : ""
                    }
                    {
                        name == 'lupus' ? <NavigationLinker to="restrictions" name='Restrictions' /> : ""
                    }
                </div>
            </>
        )
    }

    return (
        <section className='mainSection breedsSection'>
            <div className="contentContainer ">
                {renderContent()}
            </div>
        </section>
    );
}

export default Breed;