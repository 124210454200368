import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
    baseText: {
        fontFamily: 'HK Grotesk',
        color: 'white',
        fontSize: '1.15em'
    },
    quoteText:{
        fontFamily: 'werewolf',
        color: 'gold',
        fontSize: '1.15em'
    },
    button: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '2em',
        paddingBottom: '1em',
        border: '1px solid #e0e0db',
        opacity: '.4',
        borderRadius: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transition: 'opacity .5s',
        marginBottom: '1em',
        color: 'white',
        fontFamily: 'werewolf'
    },
    buttonHover: {
        transition: 'opacity 1s',
        backgroundColor: '#000000',
        opacity: '1'
    }
})
