import './Story.css';
import '../Layout.css';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { Text } from "react-native";
import { styles } from '../../utilities/styles';

function Epilogue() {
  function backToMenu() {
    window.scrollTo(0, 0);
  }

  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);

  function checkIfMobile() {
    window.screen.width >= 1300 ? setIsMobile(false) : setIsMobile(true)
  }

  useEffect(() => {
    if (!loading) {
      checkIfMobile()
      setLoading(true)
    }
  }, []);

  function getParentSelector() {
    if (loading) {
      if (isMobile) {
        return undefined;
      }
      else {
        return '#parent'
      }
    }
  }

  return (
    <section className="mainSection infoSection fadeIn">
      <div className="contentContainer" id='parent'>
        <div className='fadeIn'>
          <div className='storyInfo'>
            <AnimationOnScroll animateIn='fadeIn' initiallyVisible='true'>
              <h1 className="wolfHeader">Epilogue</h1>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                That is the tale of the first Silver Pack of the White Howlers… Now you might wonder what happened to them after these events I have just told?
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                Well, this is what I know…
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                Urban of the Bone Gnawers went back to his old home in Chicago with the honor of having represented his tribe in a vital battle against the Wyrm, and having brought the Garou Nation together again. He became the leader of his sept for a while and did a thorough job of keeping Chicago safe as well as making sure their kinfolk were taken care of. He kept up to date on what happened to the Howlers from then on, as he himself had now gained a deep sense of kinship with them having lived through a past life as a White Howler, and he often traveled back to the Isle of Mull to help educate new Howlers with the knowledge he had, though he never mentioned to anyone the horrors of the Black Spiral. In battle however, his sept noticed a change in him and started calling him “Urban Wraith” due to his newfound preference of striking unseen from the shadows, much to his horror…
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                Sara wrapped up her business on the Isle of Mull and headed back to her headquarters to report for her next mission. I do not know much about her time there, but not too long after, she gained Elder rank herself and became part of a council of elders in a Shadow Lord sept in Wyoming. The sept operated out of the area that had a portal to Malfeas back when we managed to bring Turtle back to the Garou Nation, and their main purpose seems to have been fighting the corruption left in the area. Having the connections that Sara had, it would not surprise me if she had many secret tasks and missions that have never been spoken of by the Galliards. She later disappeared from there, but as far as I know she is still alive, and I can only presume she returned to wherever she had made her home. I am sure I will run into her again…
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                Alexander “Sasha” Volkav eventually returned to his duties in his old sept. He did however retire shortly after. He was never quite the same again, though the Wyrm had not yet beaten his heart. He was being kept a close eye on by his fellow Shadow Lords…
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                The White Howlers sent a pack up north to pay respects and honor the dead Howlers of the past that were rediscovered. The site of their final battle was cleansed and with much effort, the portal that had led them to their doom all those years ago were finally closed...
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                Whispering Shadows stayed behind on the Isle of Mull for a long time, training new White Howlers, discovering lost rites and gifts and working to reconnect the new tribe with their spiritual heritage and tribal homeland. He also tried to help Morag pass on, however her ghost was unable to move on due to not knowing the entire truth of what had happened to her tribe. Whispering Shadows helped her become a spiritual mentor of sorts for her tribe before he went back to traveling as a Silent Strider. Since then, it seems like his adventures in Scotland restoring the White Howlers inspired him to attempt to help his own tribe reclaim their own homeland. Maybe this is why Owl had sent him there in the first place...
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                As for Caelan, as the first leader of the revived White Howlers, his days were always busy. He was training, recruiting, going on spirit quests in the Umbra and journeying through his own mind, searching for any and all specks of lore he could to strengthen his new tribe. Many Garou eventually joined him on the Isle of Mull, inspired by the tales of “The Perfect Metis”. There are many stories of his exploits, too many to get into here, but a few things ought to be mentioned.
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                He learned that he possessed some form of corruption that could never be removed no matter what he tried and he even attempted going to Erebus to get rid of it with no luck. He also never managed to have children, like all of us metis, but he stayed honorably mated with Olivia. Caelan made it clear to the other tribes that the Howlers would accept any metis from the other tribes into their ranks, and many came to join. He ended up having more “children” than any single Garou through his tribe, and eventually he was known as “The White Father”.
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                The Black Spiral Dancers however, declared war on them and never stopped hunting Howlers with extreme aggression. Sometimes to the detriment of their own safety. The Howlers on the other hand have their own pack, specialized in hunting the Dancers and undoing the mistakes of the past. They are known as “Luna's Howlers”.
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                Kiera and Christopher returned to San Francisco, Kiera became an elder finally and took over the leadership of the Sept of the Western Eye. She put down her pilgrim's staff and her red cloak and became the protector of her people there.
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
                <Text style={styles.baseText}>
                    Christopher returned with significant knowledge of the spiritual world of the Wyrm back to his tribe. Whether this knowledge had anything to do with it or not, ever since then, the Glass Walkers have gained as much power as the other tribes have lost. Some Theurges speak of an infinite spiritual web enveloping the world, and of the Glass Walkers helping it grow…
                </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                This world seems ever more to belong to the Weaver...
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                - Teller of Stories
              </Text>
            </AnimationOnScroll>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Epilogue;