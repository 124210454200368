import snow from '../../static/media/Snow.mp4';
import './Home.css';
import Logo from '../../static/images/Logo_Highlands.png';

const Home = () => {
    return (
        <section className="homeSection">
            <div className='fadeIn'>
                <video className='myVideo' autoPlay loop muted>
                    <source src={snow} type='video/mp4' />
                </video>
                <div className='logoContainer'>
                    <img src={Logo} className="logoHome fadeInSlowest" alt="Werewolf: Highlands Logo" />
                </div>
            </div>
        </section>
    );
};

export default Home;