import './Auspices.css';
import '../../Layout.css';
import { Link } from 'react-router-dom';
import { Text, StyleSheet} from 'react-native';
import { styles } from '../../../utilities/styles';
import ahroun from '../../../static/icons/auspices/ahroun.svg'
import galliard from '../../../static/icons/auspices/galliard.svg'
import philodox from '../../../static/icons/auspices/philodox.svg'
import theurge from '../../../static/icons/auspices/theurge.svg'
import ragabash from '../../../static/icons/auspices/ragabash.svg'

const Auspices = () => {
  return (
    <section className="mainSection auspicesSection fadeIn">
      <div className="contentContainer">
        <h1 className='wolfHeader'>Auspices</h1>
        <div className="auspicesGrid">
          <Link className='button auspiceButton' to="/auspices/ragabash"><img src={ragabash} className='buttonLogo'></img>Ragabash</Link>
          <Link className='button auspiceButton' to="/auspices/theurge"><img src={theurge} className='buttonLogo'></img>Theurge</Link>
          <Link className='button auspiceButton' to="/auspices/philodox"><img src={philodox} className='buttonLogo'></img>Philodox</Link>
          <Link className='button auspiceButton' to="/auspices/galliard"><img src={galliard} className='buttonLogo'></img>Galliard</Link>
          <Link className='button auspiceButton' to="/auspices/ahroun"><img src={ahroun} className='buttonLogo'></img>Ahroun</Link>
        </div>
        <Text style={styles.baseText}>
        Gaia is the Mother, but werewolves also feel a powerful spiritual bond to her sister Luna. Whatever else happens, when the moon is in the sky, the Garou feel stronger. The influence of Luna provides a blessing at birth that guides each werewolf ’s spiritual path. This path, this blessing, is called the auspice.
        {"\n"}{"\n"}An auspice is many things. It may influence the werewolf ’s general personality traits, attitudes and interests; it strongly influences his duties in the pack. All auspices are important, for no werewolf can be all things to his people. Each specialty strengthens the pack as a whole when they focus as one. Auspice also determines the inner Rage of the werewolf. Some Garou mothers try to use herbs or other methods to induce labor under a specific moon, which is one of the reasons that Ragabash and Ahroun are roughly as common as the other three auspices, even though the full moon and new moon appear only half as often as any other phase.
        {"\n"}{"\n"}Each young werewolf studies with an elder of the same auspice, learning particular Gifts and the role Luna has decreed for him in werewolf society. Many werewolves introduce themselves by auspice and tribe to one another: “Kolvar Irontongue, Ahroun to the Shadow Lords” says volumes. Whether the werewolf was born under a waxing or waning moon also shows some influence on his auspice and temperament. The waxing moon is a sign of rising Rage, while the waning moon hints at a cooler, more somber personality. Players might take this aspect of a character’s auspice into account when considering some of the character’s minor personality quirks.
        {"\n"}{"\n"}Auspice is an influence, not a law. Some werewolves discover they’re badly suited for the blessing of their birth moon. Even though doing so is a direct insult to Luna, they may change auspices by renouncing their former auspice and identity through a Rite of Renunciation. This rite is a grave thing for any werewolf to consider. In addition to losing any former rank and Gifts to begin in his new auspice at Rank 1, he’s sure to face the deep mistrust of others until he has proven his decision more than justified, if not for the rest of his life.
        </Text>
      </div>
    </section>
  );
};

export default Auspices;