import './Status.css';
import '../../Layout.css';
import { Link } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import { Text } from "react-native";
import React, { useState, useEffect } from "react";
import { toTitleCase } from '../../../utilities/toTitleCase';
import { baseApiUrl } from '../../../utilities/baseApiUrl';
import NavigationLinker from '../../../Common/NavigationLinker';
import { getItemFromArray } from '../../../utilities/getItemFromArray';
import { getCurrentPageFromLocation } from '../../../utilities/getCurrentPage';
import { PrintDots } from '../../../utilities/dotprinter';
import { styles } from '../../../utilities/styles';

function Status() {
  const [data, setData] = useState([]);
  const currentPage = getCurrentPageFromLocation(useLocation());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loading) {
      loadData();
    }
  }, []);

  const loadData = async () => {
    await fetch(baseApiUrl + 'rules/status')
      .then(response => response.json())
      .then(data => setData(data))
      .then(setLoading(true));
  }

  function renderSubSections(dataToCheck) {
    if (dataToCheck !== undefined) {
      return (
        <div>
          {dataToCheck.map((item) => (
            <div>
              <h3>{item.name}</h3>
              <Text style={styles.baseText}>{item.description}</Text>
            </div>
          ))}
        </div>
      )
    }
  }

  function renderContent() {
    if (loading) {
      return (
        <div className='fadeIn'>
          <h1 className="wolfHeader">{data.name}</h1>
        <Text style={styles.baseText}>{data.description}</Text>
        {renderSubSections(data.subsections)}
        </div>
      )
    }
    else {
      return (
        <div className='fadeIn'>

        </div>
      )
    }
  }

  return (
    <section className="mainSection statusSection fadeIn">
      <div className="contentContainer">
        {renderContent()}
      </div>
    </section>
  );
};

export default Status;