import './Misc.css';
import '../Layout.css';
import '../../static/nf.css';
import { Link } from 'react-router-dom';

const Music = () => {
  return (
    <section className="mainSection musicSection fadeIn">
      <div className="contentContainer">
        <h1 className="wolfHeader">Music</h1>
        <div className='sheetsContent'>
          <a href='https://open.spotify.com/playlist/2srVIqhpeLE7TQqUPWtCBX?si=e2834030fea7497a'><button className='button miscButton musicButton'><h1 className='nf nf-mdi-spotify mediaIcon'/>Werewolf - Combat</button></a>
          <a href='https://open.spotify.com/playlist/5LeEr10wZb5pAv2pbHjDQU?si=02966d7f424246f0'><button className='button miscButton musicButton'><h1 className='nf nf-mdi-spotify mediaIcon'/>Werewolf - Frenzy</button></a>
          <a href='https://open.spotify.com/playlist/066CNKGDP1IxwPW71kSuS9?si=6f9344fe637c482e'><button className='button miscButton musicButton'><h1 className='nf nf-mdi-spotify mediaIcon'/>Werewolf - Caern</button></a>
          <a href='https://open.spotify.com/playlist/6phNXxbX8PZ7IlxIgor7bh?si=7abf06f8cc984f56'><button className='button miscButton musicButton'><h1 className='nf nf-mdi-spotify mediaIcon'/>Werewolf - Wilds</button></a>
          <a href='https://open.spotify.com/playlist/3SgPB5dmLCD0CtIOR6FBdO?si=51e34da7efc64bea'><button className='button miscButton musicButton'><h1 className='nf nf-mdi-spotify mediaIcon'/>Werewolf - City</button></a>
          <a href='https://open.spotify.com/playlist/5a4BeW8dXH57NIq7yYrzpO?si=5d93cbf65ce04407'><button className='button miscButton musicButton'><h1 className='nf nf-mdi-spotify mediaIcon'/>Werewolf - Wyrm</button></a>
          <a href='https://open.spotify.com/playlist/6d8LBjmmyt91JgobgrnN1r?si=1e5ef2bda59f46db'><button className='button miscButton musicButton'><h1 className='nf nf-mdi-spotify mediaIcon'/>Werewolf - Umbra</button></a>
        </div>
      </div>
    </section>
  );
};

export default Music;