import './Menu.css';
import './MenuItem/MenuItem.css'
import MenuItem from './MenuItem/MenuItem';
import garou from '../static/images/Garou.svg'
import GoBackTo from '../utilities/backButtonRoutes';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import clawsBars from '../static/icons/ClawsBars.svg';
import xClaws from '../static/icons/XClaws.svg';

function Menu() {
    let links = [
        { linktext: "Story", linkpath: "", sublinks: [{ label: "Info", path: "/info" }, { label: "Epilogue", path: "/epilogue" }, /*{ label: "Characters", path: "/characters" }*/] },
        { linktext: "Character", linkpath: "", sublinks: [{ label: "Breeds", path: "/breeds" }, { label: "Auspices", path: "/auspices" }, { label: "Tribes", path: "/tribes" }, { label: "Attributes", path: "/attributes" }, { label: "Abilities", path: "/abilities" }, { label: "Gifts", path: "/gifts" }] },
        { linktext: "Rules", linkpath: "", sublinks: [{ label: "Combat", path: "/combat" }, { label: "Health", path: "/health" }, { label: "Willpower", path: "/willpower" }, { label: "Rage", path: "/rage" }, { label: "Gnosis", path: "/gnosis" }, { label: "Frenzy", path: "/frenzy" }, { label: "Status Effects", path: "/status" }, { label: "Equipment", path: "/equipment" }] },
        { linktext: "Misc", linkpath: "", sublinks: [{ label: "Sheets", path: "/sheets" }, { label: "Music", path: "/music" }] }
    ];
    const [showMenu, setShowMenu] = useState(false);

    return (
        <div className={showMenu ? 'navMenu-mobile' : 'navMenu'}>
            <a className='navbar-brand' href="/">
                <img className="logo" src={garou} alt="Home" />
            </a>
            {
                links.map((item, index) => (
                    <MenuItem key={item.linktext} linktext={item.linktext} linkpath={item.linkpath} sublinks={item.sublinks} mobile={showMenu} menuStateChanger={setShowMenu} />
                ))
            }
                <div className='hamburgerMenuIcon' onClick={() => setShowMenu(!showMenu)}><img src={showMenu ? xClaws : clawsBars}/></div>
        </div>
    );
}

export default Menu;