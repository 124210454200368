import './Tribes.css';
import '../../Layout.css';
import { Link } from 'react-router-dom';
import { Text, StyleSheet} from 'react-native';
import { styles } from '../../../utilities/styles';
import furies from '../../../static/icons/tribes/furies.svg'
import gnawers from '../../../static/icons/tribes/gnawers.svg'
import children from '../../../static/icons/tribes/children.svg'
import croatan from '../../../static/icons/tribes/croatan.svg'
import fianna from '../../../static/icons/tribes/fianna.svg'
import fenris from '../../../static/icons/tribes/fenris.svg'
import walkers from '../../../static/icons/tribes/walkers.svg'
import talons from '../../../static/icons/tribes/talons.svg'
import lords from '../../../static/icons/tribes/lords.svg'
import striders from '../../../static/icons/tribes/striders.svg'
import fangs from '../../../static/icons/tribes/fangs.svg'
import stargazers from '../../../static/icons/tribes/stargazers.svg'
import uktena from '../../../static/icons/tribes/uktena.svg'
import wendigo from '../../../static/icons/tribes/wendigo.svg'

const Tribes = () => {
  return (
    <section className="mainSection tribesSection fadeIn">
      <div className="contentContainer">
        <h1 className='wolfHeader'>Tribes</h1>
        <div className="tribesGrid">
          <Link className='button tribeButton' to="/tribes/furies"><img src={furies} className='buttonLogo'></img>Black Furies</Link>
          <Link className='button tribeButton' to="/tribes/gnawers"><img src={gnawers} className='buttonLogo'></img>Bone Gnawers</Link>
          <Link className='button tribeButton' to="/tribes/children"><img src={children} className='buttonLogo'></img>Children of Gaia</Link>
          <Link className='button tribeButton' to="/tribes/croatan"><img src={croatan} className='buttonLogo'></img>Croatan</Link>
          <Link className='button tribeButton' to="/tribes/fianna"><img src={fianna} className='buttonLogo'></img>Fianna</Link>
          <Link className='button tribeButton' to="/tribes/fenris"><img src={fenris} className='buttonLogo'></img>Get of Fenris</Link>
          <Link className='button tribeButton' to="/tribes/walkers"><img src={walkers} className='buttonLogo'></img>Glass Walkers</Link>
          <Link className='button tribeButton' to="/tribes/talons"><img src={talons} className='buttonLogo'></img>Red Talons</Link>
          <Link className='button tribeButton' to="/tribes/lords"><img src={lords} className='buttonLogo'></img>Shadow Lords</Link>
          <Link className='button tribeButton' to="/tribes/striders"><img src={striders} className='buttonLogo'></img>Silent Striders</Link>
          <Link className='button tribeButton' to="/tribes/fangs"><img src={fangs} className='buttonLogo'></img>Silver Fangs</Link>
          <Link className='button tribeButton' to="/tribes/stargazers"><img src={stargazers} className='buttonLogo'></img>Stargazers</Link>
          <Link className='button tribeButton' to="/tribes/uktena"><img src={uktena} className='buttonLogo'></img>Uktena</Link>
          <Link className='button tribeButton' to="/tribes/wendigo"><img src={wendigo} className='buttonLogo'></img>Wendigo</Link>
        </div>
        <Text style={styles.baseText}>
        The tradition of werewolves coming together in packs of one tribe is long past, as the Garou are now too few in number to have that luxury. Instead, packs consist of werewolves from many tribes, who each bring their own context for who the Garou are and how they can best fight the Wyrm. Each tribe has a spirit totem that reflects the tribe’s characteristics. Some tribes have ties to various human cultures and ethnicities, though the strength of these ties is waning. Choose your character’s tribe with care, as it will impact both her background, and her choice of Backgrounds. Players can choose any of the 13 tribes of the Garou Nation. The tribes are described further under the "Tribes" section.
        </Text>
      </div>
    </section>
  );
};

export default Tribes;