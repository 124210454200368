import './App.css';
import Layout from './pages/Layout';
import Home from './pages/Home/Home';
import Router from './Router';


function App() {
  return (
      <Router>
          <Layout />
          <Home />
        </Router>
  );
}

export default App;
