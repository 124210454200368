import './Breeds.css';
import '../../Layout.css';
import { Link } from 'react-router-dom';
import { Text, StyleSheet} from 'react-native';
import { styles } from '../../../utilities/styles';
import homid from '../../../static/icons/breeds/homid.svg'
import metis from '../../../static/icons/breeds/metis.svg'
import lupus from '../../../static/icons/breeds/lupus.svg'

const Breeds = () => {
  return (
    <section className="mainSection breedsSection fadeIn">
      <div className="contentContainer">
        <h1 className='wolfHeader'>Breeds</h1>
        <div className="breedsGrid">
          <Link className='button breedsButton' to="/breeds/homid"><img src={homid} className='buttonLogo'></img>Homid</Link>
          <Link className='button breedsButton' to="/breeds/metis"><img src={metis} className='buttonLogo'></img>Metis</Link>
          <Link className='button breedsButton' to="/breeds/lupus"><img src={lupus} className='buttonLogo'></img>Lupus</Link>
        </div>
        <Text style={styles.baseText}>
          Werewolves are wolf, human, and spirit melded into one. But they have to come from somewhere. A werewolf ’s breed is a function of immediate parentage, never perfectly balanced, always slightly askew. Each Garou’s breed is determined by his or her mother’s natural form. Homids are born to human or homid Garou mothers; lupus are born to wolves or lupus Garou. Only metis, the children of Garou-Garou pairings, are born in a different breed form than their mother’s. The father still contributes some genetics to the child, but affects breed only if he’s a werewolf as well. A female lupus Garou who takes on human form and mates with a human man will bear wolf cubs or, perhaps, a lupus cub or two.
          {"\n"}{"\n"}Female werewolves who bear offspring always wear their breed form when giving birth. The only exceptions to this rule are those pregnant with metis; they give birth in Crinos for the sake of survival. Werewolves can be born without a werewolf parent as well. Some come from Kinfolk lines with no immediate Garou parentage. Every so often, a werewolf child is born to an ordinary human or wolf mother who may be many generations removed from werewolf and Kin.
          {"\n"}{"\n"}Each breed has its own strengths and weaknesses, and each group has a slightly different connection to Gaia. Lupus have a stronger connection to the wild. Homids have the experience and talent to move in human society. Even metis, despite their flaws, have remarkable advantages thanks to having their natural form be Crinos war-form. Breed should inform your character both with interesting mechanics and inspiring possibilities for a backstory.
        </Text>
      </div>
    </section>
  );
};

export default Breeds;