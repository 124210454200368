import { NavLink } from "react-router-dom";
import styles from './Common.css';

function NavigationLinker(props) {
    return (
        <NavLink to={props.to} className={
            ({ isActive }) => isActive ? `navigationActive navLink` : `navigationInactive navLink`
        }>{props.name}</NavLink>
    )
}

export default NavigationLinker;