import './Abilities.css';
import '../../Layout.css';
import { useParams } from 'react-router-dom';
import { Text } from "react-native";
import { PrintDots } from '../../../utilities/dotprinter';
import React, { useState, useEffect } from "react";
import { ArrayToString } from '../../../utilities/arrayToString';
import { baseApiUrl } from '../../../utilities/baseApiUrl';
import { styles } from '../../../utilities/styles';
import NavigationLinker from '../../../Common/NavigationLinker';
import GoBackTo from '../../../utilities/backButtonRoutes';
import { getCurrentPageFromLocation } from '../../../utilities/getCurrentPage';

function Ability() {
    //data state // storage
    const [data, setData] = useState([]);
    const { name } = useParams();
    const [loading, setLoading] = useState(false);
    const logo = require('../../../static/icons/abilities/' + name + '.png');

    useEffect(() => {
        if (!loading) {
            loadData();
        }
    }, [name]);

    const loadData = async () => {
        await fetch(baseApiUrl + 'abilities/' + name)
            .then(response => response.json())
            .then(data => setData(data))
            .then(setLoading(true));
    }

    function renderLevels(dataToCheck) {
        if (dataToCheck !== undefined) {
            return (
                <div className='subContainer dotLevelSection'>
                    <h3 className="wolfHeader">{data.name} Dots</h3>
                    <div className='dotLevel'>
                        <PrintDots amount='1' />
                        <Text style={styles.baseText}>
                            {data.levels.one}
                        </Text>
                    </div>
                    <div className='dotLevel'>
                        <PrintDots amount='2' />
                        <Text style={styles.baseText}>
                            {data.levels.two}
                        </Text>
                    </div>
                    <div className='dotLevel'>
                        <PrintDots amount='3' />
                        <Text style={styles.baseText}>
                            {data.levels.three}
                        </Text>
                    </div>
                    <div className='dotLevel'>
                        <PrintDots amount='4' />
                        <Text style={styles.baseText}>
                            {data.levels.four}
                        </Text>
                    </div>
                    <div className='dotLevel'>
                        <PrintDots amount='5' />
                        <Text style={styles.baseText}>
                            {data.levels.five}
                        </Text>
                    </div>
                </div>
            )
        }
    }

    function renderSpecialties(dataToCheck) {
        if (dataToCheck !== undefined) {
            return (
                <div>
                    <h3 className="wolfHeader">Specialties</h3>
                    <Text style={styles.baseText}>
                        {ArrayToString(data.specialties)}
                    </Text>
                </div>
            )
        }
    }

    function renderPossession(dataToCheck) {
        if (dataToCheck !== undefined) {
            return (
                <div>
                    <h3 className="wolfHeader">Possessed by</h3>
                    <Text style={styles.baseText}>
                        {ArrayToString(data.possessedBy)}
                    </Text>
                </div>
            )
        }
    }

    function renderContent() {
        if (loading) {
            return (
                <div className='fadeIn'>
                    <ul className='headerArea'>
                        <li>
                            <GoBackTo />
                        </li>
                        <li>
                            <h1 className='wolfHeader'>{data.name}</h1>
                        </li>
                    </ul>
                    <span className='characterLogoContainer'>
                        <img className='characterLogo' src={logo} />
                    </span>
                    <div className="subContainer">
                    <Text style={styles.baseText}>
                            {data.description}
                        </Text>
                    </div>
                    {renderLevels(data.levels)}
                    {renderPossession(data.possessedBy)}
                    {renderSpecialties(data.specialties)}
                </div>
            )
        }
        else {
            return (
                <div className='fadeIn'>

                </div>
            )
        }
    }
    return (
        <section className='mainSection abilitiesSection'>
            <div className="contentContainer">
                {renderContent()}
            </div>
        </section>
    );
}

export default Ability;