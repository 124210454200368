export function PrintDots(props) {
    let dots = "";
    
    for (let index = 0; index < props.amount; index++) {
        dots += "●";
    }

    return ( <div> {dots} </div>);
}

export function PrintEmptyDots(props) {
    let dots = "";
    
    for (let index = 0; index < props.amount; index++) {
        dots += "○";
    }

    return ( <div> {dots} </div>);
}