import './Tribes.css';
import '../../Layout.css';
import { useParams, useLocation } from 'react-router-dom';
import { Text } from "react-native";
import React, { useState, useEffect } from "react";
import { ArrayToString } from '../../../utilities/arrayToString';
import { baseApiUrl } from '../../../utilities/baseApiUrl';
import { styles } from '../../../utilities/styles';
import NavigationLinker from '../../../Common/NavigationLinker';
import GoBackTo from '../../../utilities/backButtonRoutes';
import { getCurrentPageFromLocation } from '../../../utilities/getCurrentPage';

function Tribe() {
    //data state // storage
    const [data, setData] = useState([]);
    const { name } = useParams();
    const currentPage = getCurrentPageFromLocation(useLocation());
    const logo = require('../../../static/images/logos/' + name + '.png');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            loadData();
        }
    }, [name]);

    const loadData = async () => {
        await fetch(baseApiUrl + 'tribes/' + name)
            .then(response => response.json())
            .then(data => setData(data))
            .then(setLoading(true));
    }

    function renderStartingGifts(dataToCheck) {
        if (dataToCheck !== undefined) {
            return (
                <div>
                    <h2 className='wolfHeader'>Starting Gifts</h2>
                    <Text style={styles.baseText}>
                        {ArrayToString(data.gifts)}
                    </Text>
                </div>
            )
        }
    }

    function renderInfo() {
        return (
            <div>
                <h2 className='wolfHeader'>Initial Willpower</h2>
                <Text style={styles.baseText}>
                    {data.willpower}
                </Text>
                <h2 className='wolfHeader'>Description</h2>
                <Text style={styles.baseText}>
                    {data.description}
                </Text>
            </div>
        )
    }

    function renderTotem() {
        return (
            <div>
                <h2 className='wolfHeader'>Totem</h2>
                <Text style={styles.baseText}>
                    {data.totem}
                </Text>
            </div>
        )
    }

    function renderStereotypes() {
        if(data.stereotypesTribes !== undefined)
        return (
            <div>
                {
                    data.stereotypesTribes.furies !== "" ? <div><h3 className='wolfHeader'>Black Furies</h3><Text style={styles.baseText}>{data.stereotypesTribes.furies}</Text></div> : ""
                }
                {
                    data.stereotypesTribes.gnawers !== "" ? <div><h3 className='wolfHeader'>Bone Gnawers</h3><Text style={styles.baseText}>{data.stereotypesTribes.gnawers}</Text></div> : ""
                }
                {
                    data.stereotypesTribes.children !== "" ? <div><h3 className='wolfHeader'>Children of Gaia</h3><Text style={styles.baseText}>{data.stereotypesTribes.children}</Text></div> : ""
                }
                {
                    data.stereotypesTribes.fianna !== "" ? <div><h3 className='wolfHeader'>Fianna</h3><Text style={styles.baseText}>{data.stereotypesTribes.fianna}</Text></div> : ""
                }
                {
                    data.stereotypesTribes.fenris !== "" ? <div><h3 className='wolfHeader'>Get of Fenris</h3><Text style={styles.baseText}>{data.stereotypesTribes.fenris}</Text></div> : ""
                }
                {
                    data.stereotypesTribes.walkers !== "" ? <div><h3 className='wolfHeader'>Glass Walkers</h3><Text style={styles.baseText}>{data.stereotypesTribes.walkers}</Text></div> : ""
                }
                {
                    data.stereotypesTribes.talons !== "" ? <div><h3 className='wolfHeader'>Red Talons</h3><Text style={styles.baseText}>{data.stereotypesTribes.talons}</Text></div> : ""
                }
                {
                    data.stereotypesTribes.lords !== "" ? <div><h3 className='wolfHeader'>Shadow Lords</h3><Text style={styles.baseText}>{data.stereotypesTribes.lords}</Text></div> : ""
                }
                {
                    data.stereotypesTribes.striders !== "" ? <div><h3 className='wolfHeader'>Silent Striders</h3><Text style={styles.baseText}>{data.stereotypesTribes.striders}</Text></div> : ""
                }
                {
                    data.stereotypesTribes.fangs !== "" ? <div><h3 className='wolfHeader'>Silver Fangs</h3><Text style={styles.baseText}>{data.stereotypesTribes.fangs}</Text></div> : ""
                }
                {
                    data.stereotypesTribes.stargazers !== "" ? <div><h3 className='wolfHeader'>Stargazers</h3><Text style={styles.baseText}>{data.stereotypesTribes.stargazers}</Text></div> : ""
                }
                {
                    data.stereotypesTribes.uktena !== "" ? <div><h3 className='wolfHeader'>Uktena</h3><Text style={styles.baseText}>{data.stereotypesTribes.uktena}</Text></div> : ""
                }
                {
                    data.stereotypesTribes.wendigo !== "" ? <div><h3 className='wolfHeader'>Wendigo</h3><Text style={styles.baseText}>{data.stereotypesTribes.wendigo}</Text></div> : ""
                }
            </div>
        )
    }

    function renderAppearance() {
        return (
            <div>
                <h2 className='wolfHeader'>Appearance</h2>
                <Text style={styles.baseText}>
                    {data.appearance}
                </Text>
            </div>
        )
    }

    function renderHistory() {
        return (
            <div>
                <h2 className='wolfHeader'>Creation</h2>
                <Text style={styles.baseText}>
                    {data.creation}
                </Text>
                <h2 className='wolfHeader'>Territory</h2>
                <Text style={styles.baseText}>
                    {data.territory}
                </Text>
            </div>
        )
    }

    function renderCamps() {
        if (data.camps !== undefined)
            return (
                <div>
                    {data.camps.map((camp) => (
                        <div key={camp}>
                            <Text style={styles.baseText}>
                                {camp}
                            </Text>
                        </div>
                    ))}
                </div>
            )
    }

    function renderContent() {
        if (loading) {
            return (
                <div className='fadeIn'>
                    <ul className='headerArea'>
                        <li>
                            <GoBackTo />
                        </li>
                        <li>
                            <h1 className='wolfHeader'>{data.name}</h1>
                        </li>
                    </ul>
                    <span className='characterLogoContainer'>
                        <img className='characterLogo' src={logo} />
                    </span>
                    <Text style={styles.quoteText}>
                        {data.quote}
                    </Text>
                    <TribeNavigations />
                    {
                        currentPage === 'info' ? renderInfo() : ""
                    }
                    {
                        currentPage === 'totem' ? renderTotem() : ""
                    }
                    {
                        currentPage === 'appearance' ? renderAppearance() : ""
                    }
                    {
                        currentPage === 'history' ? renderHistory() : ""
                    }
                    {
                        currentPage === 'camps' ? renderCamps() : ""
                    }
                    {
                        currentPage === 'gifts' ? renderStartingGifts(data.gifts) : ""
                    }
                    {
                        currentPage === 'stereotypes' ? renderStereotypes() : ""
                    }
                </div>
            )
        }
        else {
            return (
                <div className='fadeIn'>

                </div>
            )
        }
    }

    function TribeNavigations() {
        return (
            <>
                <div className={`tabMenu contentTab`}>
                    <NavigationLinker to="info" name="Information" />
                    <NavigationLinker to="totem" name="Totem" />
                    <NavigationLinker to="appearance" name="Appearance" />
                    <NavigationLinker to="history" name="History" />
                    <NavigationLinker to="camps" name="Camps" />
                    <NavigationLinker to="gifts" name="Starting Gifts" />
                    <NavigationLinker to="stereotypes" name="Stereotypes" />
                </div>
            </>
        )
    }

    return (
        <section className='mainSection tribesSection'>
            <div className="contentContainer">
                {renderContent()}
            </div>
        </section>
    );
}

export default Tribe;