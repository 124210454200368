import './Combat.css';
import '../../../Common/Animations.css'
import '../../../Common/Common.css'
import '../../Layout.css';
import { useLocation, useParams } from 'react-router-dom';
import { Text } from "react-native";
import React, { useState, useEffect } from "react";
import { toTitleCase } from '../../../utilities/toTitleCase';
import { baseApiUrl } from '../../../utilities/baseApiUrl';
import NavigationLinker from '../../../Common/NavigationLinker';
import { getItemFromArray } from '../../../utilities/getItemFromArray';
import { getCurrentPageFromLocation } from '../../../utilities/getCurrentPage';
import { PrintDots } from '../../../utilities/dotprinter';
import { styles } from '../../../utilities/styles';

function CombatArts() {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const currentPage = getCurrentPageFromLocation(useLocation());

    useEffect(() => {
        if (!loading) {
            loadData();
        }
    }, []);

    const loadData = async () => {
        await fetch(baseApiUrl + 'rules/combat_arts')
            .then(response => response.json())
            .then(data => setData(data))
            .then(setLoading(true));
    }

    function renderLowerPage(dataToCheck) {
        if (dataToCheck !== undefined) {
            return (
                <div className="fadeIn">
                    <h2 className='wolfHeader'>{dataToCheck.name}</h2>
                    <Text style={styles.baseText}>{dataToCheck.description}</Text>
                    <div className='contentTab'><Text style={styles.baseText}>{dataToCheck.requirements}</Text></div>
                    {renderArts(dataToCheck.subsections)}
                </div>
            )
        }
    }

    function renderArts(dataToCheck) {
        if (dataToCheck !== undefined) {
            return (
                <div>
                    {dataToCheck.map((item) => (
                        <div key={item.name}>
                            <h3><PrintDots amount={item.level} /> {item.name}</h3>
                            <div>
                                <Text style={styles.baseText}>{item.description}</Text>
                            </div>
                        </div>
                    ))}
                </div>
            )
        }
    }

    function ArtsNavigations() {
        if(data !== undefined)
        {
            return (
                <>
                    <div className={`tabMenu contentTab`}>
                        <NavigationLinker to="iskakku" name="Iskakku" />
                        <NavigationLinker to="kailindo" name="Kailindo" />
                        <NavigationLinker to="klaviskar" name="Klaviskar" />
                    </div>
                </>
            )
        }
        else
            return(<></>)
    }

    function renderContent() {
        if (loading) {
            return (
                <div className='fadeIn'>
                    <h1 className="wolfHeader">{data.name}</h1>
                    <Text style={styles.baseText}>
                        {data.description}
                    </Text>
                    <ArtsNavigations />
                    {renderLowerPage(getItemFromArray(toTitleCase(currentPage), data.tabs, "name"))}
                </div>
            )
        }
        else {
            return (
                <div className='fadeIn'>

                </div>
            )
        }
    }

    return (
        <section className="mainSection combatRulesSection">
            <div className="contentContainer">
                {renderContent()}
            </div>
        </section>
    );
};

export default CombatArts;