import './Attributes.css';
import '../../Layout.css';
import '../../../static/nf.css';
import { Link } from 'react-router-dom';
import strength from '../../../static/icons/attributes/strength.svg'
import dexterity from '../../../static/icons/attributes/dexterity.svg'
import stamina from '../../../static/icons/attributes/stamina.svg'
import charisma from '../../../static/icons/attributes/charisma.svg'
import manipulation from '../../../static/icons/attributes/manipulation.svg'
import appearance from '../../../static/icons/attributes/appearance.svg'
import perception from '../../../static/icons/attributes/perception.svg'
import intelligence from '../../../static/icons/attributes/intelligence.svg'
import wits from '../../../static/icons/attributes/wits.svg'


const Attributes = () => {
  return (
    <section className="mainSection attributesSection fadeIn">
      <div className="contentContainer">
        <h1 className='wolfHeader fadeIn'>Attributes</h1>
        <div className="attributesGrid fadeIn">
          <div className="attributesRow">
            <h2 className="wolfHeader">Physical</h2>
            <Link className='button attributesButton' to="/attributes/strength"><img src={strength} className='buttonLogo'></img>Strength</Link>
            <Link className='button attributesButton' to="/attributes/dexterity"><img src={dexterity} className='buttonLogo'></img>Dexterity</Link>
            <Link className='button attributesButton' to="/attributes/stamina"><img src={stamina} className='buttonLogo'></img>Stamina</Link>
          </div>
          <div className="attributesRow">
            <h2 className="wolfHeader">Social</h2>
            <Link className='button attributesButton' to="/attributes/charisma"><img src={charisma} className='buttonLogo'></img>Charisma</Link>
            <Link className='button attributesButton' to="/attributes/manipulation"><img src={manipulation} className='buttonLogo'></img>Manipulation</Link>
            <Link className='button attributesButton' to="/attributes/appearance"><img src={appearance} className='buttonLogo'></img>Appearance</Link>
          </div>
          <div className="attributesRow">
            <h2 className="wolfHeader">Mental</h2>
            <Link className='button attributesButton' to="/attributes/perception"><img src={perception} className='buttonLogo'></img>Perception</Link>
            <Link className='button attributesButton' to="/attributes/intelligence"><img src={intelligence} className='buttonLogo'></img>Intelligence</Link>
            <Link className='button attributesButton' to="/attributes/wits"><img src={wits} className='buttonLogo'></img>Wits</Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Attributes;