import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const matcher = [
    {
        current: "/breeds/",
        backTo: "/breeds",
        name: "Breeds"
    },
    {
        current: "/auspices/",
        backTo: "/auspices",
        name: "Auspices"
    },
    {
        current: "/tribes/",
        backTo: "/tribes",
        name: "Tribes"
    },
    {
        current: "/attributes/",
        backTo: "/attributes",
        name: "Attributes"
    },
    {
        current: "/abilities/",
        backTo: "/abilities",
        name: "Abilities"
    },
    {
        current: "/combat/",
        backTo: "/combat",
        name: "Combat"
    }
]

function GoBackTo(){
    const location = useLocation();
    const navigator = useNavigate();
    const [currentRouteMatch, setCurrentRouteMatch] = useState({
        current: "",
        backTo: "",
        name: ""
    });

    useEffect(() => {
        for (const isMatch of matcher){
            if(location.pathname.startsWith(isMatch.current)) {
                setCurrentRouteMatch(isMatch);
                return;
            }
        }
        setCurrentRouteMatch({
            current: "",
            backTo: "",
            name: ""
        })
    }, [location])

    if(currentRouteMatch.current === "") {
        return (
            <div/>
        )
    }

    return (
        <button 
            className='button backButton' 
            onClick={() => navigator(currentRouteMatch.backTo)}
        > <span className="vtm-hk-grotesk-force">{currentRouteMatch.name}</span></button>
    )
}

export default GoBackTo;