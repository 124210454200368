import './Story.css';
import '../Layout.css';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { Text } from "react-native";
import { styles } from '../../utilities/styles';

function Info() {
  function backToMenu() {
    window.scrollTo(0, 0);
  }

  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);

  function checkIfMobile() {
    window.screen.width >= 1300 ? setIsMobile(false) : setIsMobile(true)
  }

  useEffect(() => {
    if (!loading) {
      checkIfMobile()
      setLoading(true)
    }
  }, []);

  function getParentSelector() {
    if (loading) {
      if (isMobile) {
        return undefined;
      }
      else {
        return '#parent'
      }
    }
  }

  return (
    <section className="mainSection infoSection fadeIn">
      <div className="contentContainer" id='parent'>
        <div className='fadeIn'>
          <div className='storyInfo'>
            <AnimationOnScroll animateIn='fadeIn' initiallyVisible='true'>
              <h1 className="wolfHeader">2009</h1>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                The world has changed yet again. 
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                The economic boom did not last forever.{"\n"}{"\n"}With the near-collapse of the world's financial system last September, social unrest is now spreading around the globe.
                {"\n"}{"\n"}The megacorporations are as always using the crisis to benefit themselves and grab what they can from the people.{"\n"}{"\n"}Pentex now has its tendrils in more than half of the world's corporations and growing stronger by the day.
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                Technology and surveillance are as ever improving.{"\n"}{"\n"}Smartphones can be found in the hands of anyone these days, produced through the exploitation of Gaia on a massive scale.
                {"\n"}{"\n"}Their cameras, better than ever before, further threaten the Garou's ability to act as Gaia's warriors. {"\n"}{"\n"}Hunters are now better organized and better equipped, and secret knowledge of the supernatural is spreading amongst the governments of the world.
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                These are truly the darkest of days...
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                Yet, as the power of Pentex grows, the power of the Black Spiral Dancers has weakened. In the Garou Nation, many wonder if a clash between the two champions of the Wyrm are not inevitable.
                {"\n"}{"\n"}
                However the Nation has its own struggles...
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                The success of the Moontusk Pack in 2004/2005 and the reawakening of Turtle as a Garou Totem as well as the re-establishment of a tribe thought lost forever has brought much-needed hope to the Garou.
                {"\n"}{"\n"}However, the subsequent Concolation created a heavy rift between the Get of Fenris, the Fianna and the Children of Gaia. The decision was first to wait until the Garou had rebuilt to challenge the Wyrm in Malfeas, but when King Albrecht decided to have the portal in Wyoming closed for good, the Get had had enough.
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                {"\n"}{"\n"}In 2005 the Get of Fenris called all members of their tribe back to Scandinavia to fight Ragnarok, and closed all their caerns to outsiders, as well as separated all the moon bridges connecting Scandinavia to the rest of the world. What they are up to now, nobody knows...
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                Many Garou now ask themselves whether it was worth it, the elders often being more sceptical. But some of the younger ones now also ask, if one tribe can be restored under Gaia, could not another be as well?
                {"\n"}{"\n"}Among the young, for the first time in a long time, serious debate has been waging about whether or not the restoration of the White Howlers is now possible...
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                In late 2008, while the world is reeling from the financial crisis and the Garou are busy cleaning up the spiritual aftermath, the Get of Fenris show themselves again in Scotland.
                They attack caerns and hives across the country indiscriminately to claim land and close down the caerns that they take to the rest of the Nation. No one knows why the Get has suddenly gone mad, but they seem to treat the Gaian Garou and the Black Spiral Dancers with the same hostility. Several caerns are lost to the Get invasion.
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                The Fianna pays close attention to what is happening, but when the Get claims what is believed to be a former sacred caern to the White Howlers thousands of years ago on the Isle of Mull between Scotland and Ireland, the alarm is sounded. Worst case scenario, they may be preparing to go to Ireland next and challenge the Fianna themselves.
                {"\n"}{"\n"}With the help of the Silent Striders, the Fianna send out the call to any Garou that would help them stop the Get invasion in its tracks and take back the Isle of Mull...
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                {"\n"}{"\n"}However, only you respond.
              </Text>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <h1 className='wolfHeader'>Gameplay Information</h1>
            </AnimationOnScroll>
          </div>
          <div className='storyInfoContent'>
            <AnimationOnScroll animateIn='fadeIn' scrollableParentSelector={getParentSelector()}>
              <Text style={styles.baseText}>
                You will all play experienced Garou starting at the rank of Adren at the very least. You will all start with the starting gifts of your breed, auspice and tribe (1 of each, rank 1) as well as one additional gift from any of the categories for each rank beyond the first.
                {"\n"}{"\n"}It is highly recommended not to play a Get of Fenris character, but playing one that has deserted their tribe will be possible to work in. You will in this case lack access to a lot of your tribe benefits.
                {"\n"}{"\n"}To reflect the experience you have as Garou, you will start with 35 freebie points during character creation.
                {"\n"}{"\n"}If your character has had freebie points invested during character creation in the past, you can spend additional freebie points to a total number of 35.
                {"\n"}{"\n"}Purchasing of additional rank is possible to the price of 20 freebie points. This includes the required renown as well as one extra gift for your current rank. Renown can otherwise be purchased at a prize of 2 FP per renown.
                {"\n"}{"\n"}A backstory describing your experience will be necessary and it needs to include how you achieved your current rank and why you have responded to the call.
                {"\n"}{"\n"}Character creation will be done together during the first session with new sheets, but you are welcome to think about how you want to distribute your freebie points beforehand.
                {"\n"}{"\n"}The in-game start date will be in January of 2009.
              </Text>
            </AnimationOnScroll>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Info;