import './Combat.css';
import '../../../Common/Animations.css'
import '../../../Common/Common.css'
import '../../Layout.css';
import { useLocation, useParams } from 'react-router-dom';
import { Text } from "react-native";
import React, { useState, useEffect } from "react";
import { toTitleCase } from '../../../utilities/toTitleCase';
import { baseApiUrl } from '../../../utilities/baseApiUrl';
import NavigationLinker from '../../../Common/NavigationLinker';
import { getItemFromArray } from '../../../utilities/getItemFromArray';
import { getCurrentPageFromLocation } from '../../../utilities/getCurrentPage';
import { PrintDots } from '../../../utilities/dotprinter';
import { styles } from '../../../utilities/styles';

function CombatStages() {

    const [data, setData] = useState([]);
    const currentPage = getCurrentPageFromLocation(useLocation());
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            loadData();
        }
    }, []);

    const loadData = async () => {
        await fetch(baseApiUrl + 'rules/combat_stages')
            .then(response => response.json())
            .then(data => setData(data))
            .then(setLoading(true));
    }

    function renderLowerPage(dataToCheck) {
        if (dataToCheck !== undefined) {
            return (
                <div className="fadeIn">
                    <h2 className='wolfHeader'>{dataToCheck.name}</h2>
                    <Text style={styles.baseText}>{dataToCheck.description}</Text>
                    <div className='contentTab'>{dataToCheck.requirements}</div>
                    {renderSubSections(dataToCheck.subsections)}
                </div>
            )
        }
    }

    function renderSubSections(dataToCheck) {
        if (dataToCheck !== undefined) {
            return (
                <div>
                    {dataToCheck.map((item) => (
                        <div>
                            <Text style={styles.baseText}>{item.description}</Text>
                        </div>
                    ))}
                </div>
            )
        }
    }

    function StagesNavigations() {
        return (
            <>
                <div className={`tabMenu contentTab`}>
                    <NavigationLinker to="summary" name="Summary" />
                    <NavigationLinker to="initiative" name="Initiative" />
                    <NavigationLinker to="attack" name="Attack" />
                    <NavigationLinker to="resolution" name="Resolution" />
                    <NavigationLinker to="soaking" name="Soaking" />
                    <NavigationLinker to="spending_rage" name="Spending Rage" />
                </div>
            </>
        )
    }

    function renderContent() {
        if (loading) {
            return (
                <div className='fadeIn'>
                    <h1 className="wolfHeader">{data.name}</h1>
                    <Text style={styles.baseText}>
                        {data.description}
                    </Text>
                    <StagesNavigations />
                    {renderLowerPage(getItemFromArray(currentPage, data.tabs, "id"))}
                </div>
            )
        }
        else {
            return (
                <div className='fadeIn'>

                </div>
            )
        }
    }

    return (
        <section className="mainSection combatRulesSection">
            <div className="contentContainer">
                {renderContent()}
            </div>
        </section>
    );
};

export default CombatStages;