import './Rage.css';
import '../../Layout.css';
import '../../../Common/Animations.css'
import '../../../Common/Common.css'
import { Link } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import { Text } from "react-native";
import React, { useState, useEffect } from "react";
import { toTitleCase } from '../../../utilities/toTitleCase';
import { baseApiUrl } from '../../../utilities/baseApiUrl';
import NavigationLinker from '../../../Common/NavigationLinker';
import { getItemFromArray } from '../../../utilities/getItemFromArray';
import { getCurrentPageFromLocation } from '../../../utilities/getCurrentPage';
import { PrintDots } from '../../../utilities/dotprinter';
import { styles } from '../../../utilities/styles';

function Rage() {
  const [data, setData] = useState([]);
  const currentPage = getCurrentPageFromLocation(useLocation());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loading) {
      loadData();
    }
  }, []);

  const loadData = async () => {
    await fetch(baseApiUrl + 'rules/rage')
      .then(response => response.json())
      .then(data => setData(data))
      .then(setLoading(true));
  }

  function renderLowerPage(dataToCheck) {
    if (dataToCheck !== undefined) {
      return (
        <div className="fadeIn">
          <h2 className='wolfHeader'>{dataToCheck.name}</h2>
          <Text style={styles.baseText}>{dataToCheck.description}</Text>
          {renderSubSections(dataToCheck.subsections)}
        </div>
      )
    }
  }

  function renderSubSections(dataToCheck) {
    if (dataToCheck !== undefined) {
      return (
        <div>
          {dataToCheck.map((item) => (
            <div>
              <h3>{item.name}</h3>
              <Text style={styles.baseText}>{item.description}</Text>
              {console.log(item.levels)}
              {renderLevels(item.levels)}
            </div>
          ))}
        </div>
      )
    }
  }

  function renderLevels(dataToCheck) {
    if (dataToCheck !== undefined) {
      return (
        <div>
          <h3 className="wolfHeader">Levels</h3>
          {dataToCheck.map((item) => (
            <div>
              <PrintDots amount={item.level} />
              <Text style={styles.baseText}>{item.description}</Text>
            </div>
          ))}
        </div>
      )
    }
  }

  function RageNavigations() {
    return (
      <>
        <div className={`tabMenu contentTab`}>
          <NavigationLinker to="rage" name="Rage" />
          <NavigationLinker to="using_rage" name="Using Rage" />
          <NavigationLinker to="gaining_rage" name="Gaining Rage" />
        </div>
      </>
    )
  }

  function renderContent() {
    if (loading) {
      return (
        <div className='fadeIn'>
          <h1 className="wolfHeader">{data.name}</h1>
          <Text style={styles.baseText}>{data.description}</Text>
          <RageNavigations />
          {renderLowerPage(getItemFromArray(currentPage, data.tabs, "id"))}
        </div>
      )
    }
    else {
      return (
        <div className='fadeIn'>

        </div>
      )
    }
  }

  return (
    <section className="mainSection rageSection fadeIn">
      <div className="contentContainer">
        {renderContent()}
      </div>
    </section>
  );
};

export default Rage;