import './Story.css';
import '../Layout.css';
import { useParams, useLocation } from 'react-router-dom';
import { Text } from "react-native";
import React, { useState, useEffect } from "react";
import { baseApiUrl } from '../../utilities/baseApiUrl';
import { Link } from 'react-router-dom';

function Summaries() {
  const [data, setData] = useState([]);
    const { name } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            loadData();
        }
    }, [name]);

    const loadData = async () => {
        await fetch(baseApiUrl + 'summaries/' + 'highlands')
            .then(response => response.json())
            .then(data => setData(data))
            .then(setLoading(true));
    }

    function renderContent() {
      if (loading) {
          return (
              <div className='fadeIn'>
                  <ul className='headerArea'>
                      <li>
                          <h1 className='wolfHeader'>{data.name}</h1>
                      </li>
                  </ul>
                  <h2 className='wolfHeader'>{data.title}</h2>
              </div>
          )
      }
      else {
          return (
              <div className='fadeIn'>
    
              </div>
          )
      }
    }

    return (
      <section className="mainSection summariesSection fadeIn">
        <div className="contentContainer">
          <h1 className="wolfHeader">Campaign Summaries</h1>
          {renderContent()}
        </div>
      </section>
    );
}

export default Summaries;