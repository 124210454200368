import LinkItem from './LinkItem/LinkItem';
import './MenuItem.css';
import { Link } from 'react-router-dom';

function MenuItem(props) {
  let links = props.sublinks;

  return (
    <div className={props.mobile ? 'menuItem-mobile' : 'menuItem'}>
      <div className="dropdown ">
        <button className="dropbtn">
          {props.linktext}
        </button>
        <div className="dropdown-content">
          {
            links.map((item, index) => (
              <LinkItem key={item.path} path={item.path} label={item.label} menuStateChanger={props.menuStateChanger} />
            ))
          }
        </div>
      </div>
    </div>

  )
}

export default MenuItem;