import './Abilities.css';
import '../../Layout.css';
import { Link } from 'react-router-dom';
import alertness from '../../../static/icons/abilities/alertness.svg'
import athletics from '../../../static/icons/abilities/athletics.svg'
import brawl from '../../../static/icons/abilities/brawl.svg'
import empathy from '../../../static/icons/abilities/empathy.svg'
import expression from '../../../static/icons/abilities/expression.svg'
import leadership from '../../../static/icons/abilities/leadership.svg'
import intimidation from '../../../static/icons/abilities/intimidation.svg'
import primalUrge from '../../../static/icons/abilities/primal_urge.svg'
import streetwise from '../../../static/icons/abilities/streetwise.svg'
import subterfuge from '../../../static/icons/abilities/subterfuge.svg'
import animalKen from '../../../static/icons/abilities/animal_ken.svg'
import crafts from '../../../static/icons/abilities/crafts.svg'
import drive from '../../../static/icons/abilities/drive.svg'
import etiquette from '../../../static/icons/abilities/etiquette.svg'
import firearms from '../../../static/icons/abilities/firearms.svg'
import larceny from '../../../static/icons/abilities/larceny.svg'
import melee from '../../../static/icons/abilities/melee.svg'
import performance from '../../../static/icons/abilities/performance.svg'
import stealth from '../../../static/icons/abilities/stealth.svg'
import survival from '../../../static/icons/abilities/survival.svg'
import academics from '../../../static/icons/abilities/academics.svg'
import computers from '../../../static/icons/abilities/computers.svg'
import enigmas from '../../../static/icons/abilities/enigmas.svg'
import investigation from '../../../static/icons/abilities/investigation.svg'
import law from '../../../static/icons/abilities/law.svg'
import medicine from '../../../static/icons/abilities/medicine.svg'
import occult from '../../../static/icons/abilities/occult.svg'
import rituals from '../../../static/icons/abilities/rituals.svg'
import science from '../../../static/icons/abilities/science.svg'
import technology from '../../../static/icons/abilities/technology.svg'

const Abilities = () => {
  return (
    <section className="mainSection abilitiesSection fadeIn">
      <div className="contentContainer">
        <h1 className='wolfHeader fadeIn'>Abilities</h1>
        <div className="abilitiesGrid fadeIn">
          <div>
            <h2 className="wolfHeader">Talents</h2>
            <div className="abilitiesRow">
              <Link className='button abilityButton' to="/abilities/alertness"><img src={alertness} className='buttonLogo'></img>Alertness</Link>
              <Link className='button abilityButton' to="/abilities/athletics"><img src={athletics} className='buttonLogo'></img>Athletics</Link>
              <Link className='button abilityButton' to="/abilities/brawl"><img src={brawl} className='buttonLogo'></img>Brawl</Link>
              <Link className='button abilityButton' to="/abilities/empathy"><img src={empathy} className='buttonLogo'></img>Empathy</Link>
              <Link className='button abilityButton' to="/abilities/expression"><img src={expression} className='buttonLogo'></img>Expression</Link>
              <Link className='button abilityButton' to="/abilities/leadership"><img src={leadership} className='buttonLogo'></img>Leadership</Link>
              <Link className='button abilityButton' to="/abilities/intimidation"><img src={intimidation} className='buttonLogo'></img>Intimidation</Link>
              <Link className='button abilityButton' to="/abilities/primal_urge"><img src={primalUrge} className='buttonLogo'></img>Primal-Urge</Link>
              <Link className='button abilityButton' to="/abilities/streetwise"><img src={streetwise} className='buttonLogo'></img>Streetwise</Link>
              <Link className='button abilityButton' to="/abilities/subterfuge"><img src={subterfuge} className='buttonLogo'></img>Subterfuge</Link>
            </div>
          </div>
          <div>
            <h2 className="wolfHeader">Skills</h2>
            <div className="abilitiesRow">
            <Link className='button abilityButton' to="/abilities/animal_ken"><img src={animalKen} className='buttonLogo'></img>Animal Ken</Link>
            <Link className='button abilityButton' to="/abilities/crafts"><img src={crafts} className='buttonLogo'></img>Crafts</Link>
            <Link className='button abilityButton' to="/abilities/drive"><img src={drive} className='buttonLogo'></img>Drive</Link>
            <Link className='button abilityButton' to="/abilities/etiquette"><img src={etiquette} className='buttonLogo'></img>Etiquette</Link>
            <Link className='button abilityButton' to="/abilities/firearms"><img src={firearms} className='buttonLogo'></img>Firearms</Link>
            <Link className='button abilityButton' to="/abilities/larceny"><img src={larceny} className='buttonLogo'></img>Larceny</Link>
            <Link className='button abilityButton' to="/abilities/melee"><img src={melee} className='buttonLogo'></img>Melee</Link>
            <Link className='button abilityButton' to="/abilities/performance"><img src={performance} className='buttonLogo'></img>Performance</Link>
            <Link className='button abilityButton' to="/abilities/stealth"><img src={stealth} className='buttonLogo'></img>Stealth</Link>
            <Link className='button abilityButton' to="/abilities/survival"><img src={survival} className='buttonLogo'></img>Survival</Link>
            </div>
          </div>
          <div>
            <h2 className="wolfHeader">Knowledges</h2>
            <div className="abilitiesRow">
            <Link className='button abilityButton' to="/abilities/academics"><img src={academics} className='buttonLogo'></img>Academics</Link>
            <Link className='button abilityButton' to="/abilities/computers"><img src={computers} className='buttonLogo'></img>Computers</Link>
            <Link className='button abilityButton' to="/abilities/enigmas"><img src={enigmas} className='buttonLogo'></img>Enigmas</Link>
            <Link className='button abilityButton' to="/abilities/investigation"><img src={investigation} className='buttonLogo'></img>Investigation</Link>
            <Link className='button abilityButton' to="/abilities/law"><img src={law} className='buttonLogo'></img>Law</Link>
            <Link className='button abilityButton' to="/abilities/medicine"><img src={medicine} className='buttonLogo'></img>Medicine</Link>
            <Link className='button abilityButton' to="/abilities/occult"><img src={occult} className='buttonLogo'></img>Occult</Link>
            <Link className='button abilityButton' to="/abilities/rituals"><img src={rituals} className='buttonLogo'></img>Rituals</Link>
            <Link className='button abilityButton' to="/abilities/science"><img src={science} className='buttonLogo'></img>Science</Link>
            <Link className='button abilityButton' to="/abilities/technology"><img src={technology} className='buttonLogo'></img>Technology</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Abilities;