import './LinkItem.css';
import { NavLink } from 'react-router-dom';

function LinkItem(props) {
  return (
      <NavLink to={props.path} onClick={() => props.menuStateChanger(false)} >
        {props.label}
      </NavLink>
  );
}

export default LinkItem;