import './Misc.css';
import '../Layout.css';
import { Link } from 'react-router-dom';
import w20twopage from '../../static/media/W20_2-Page.pdf'
import w20fourpage from '../../static/media/W20_4-Page.pdf'
import w20whfourpage from '../../static/media/W20_WH_4-page.pdf'

const Sheets = () => {
  return (
    <section className="mainSection sheetsSection fadeIn">
      <div className="contentContainer">
        <h1 className="wolfHeader">Sheets</h1>
        <div className='sheetsContent'>
          <a href={w20twopage}><button className='button miscButton'>W20 - 2-Page Sheet</button></a>
          <a href={w20fourpage}><button className='button miscButton'>W20 - 4-Page Sheet</button></a>
          <a href={w20whfourpage}><button className='button miscButton'>W20 - White Howlers - 4-Page Sheet</button></a>
        </div>
      </div>
    </section>
  );
};

export default Sheets;