import Layout from './pages/Layout';
import Home from './pages/Home/Home';
import Attributes from './pages/Character/Attributes/Attributes';
import Abilities from './pages/Character/Abilities/Abilities';
import Auspices from './pages/Character/Auspices/Auspices';
import Gifts from './pages/Character/Gifts/Gifts';
import Tribes from './pages/Character/Tribes/Tribes';
import Breeds from './pages/Character/Breeds/Breeds';
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import Attribute from './pages/Character/Attributes/Attribute';
import Ability from './pages/Character/Abilities/Ability';
import Auspice from './pages/Character/Auspices/Auspice';
import Breed from './pages/Character/Breeds/Breed';
import Tribe from './pages/Character/Tribes/Tribe';
import Combat from './pages/Rules/Combat/Combat';
import CombatArts from './pages/Rules/Combat/Combat_Arts';
import CombatManeuvers from './pages/Rules/Combat/Combat_Maneuvers';
import CombatStages from './pages/Rules/Combat/Combat_Stages';
import CombatTypes from './pages/Rules/Combat/Combat_Types';
import Health from './pages/Rules/Health/Health';
import Gnosis from './pages/Rules/Gnosis/Gnosis';
import Willpower from './pages/Rules/Willpower/Willpower';
import Rage from './pages/Rules/Rage/Rage';
import Frenzy from './pages/Rules/Frenzy/Frenzy';
import Status from './pages/Rules/Status/Status';
import Equipment from './pages/Rules/Equipment/Equipment';
import Info from './pages/Story/Info';
import Epilogue from './pages/Story/Epilogue';
import Summaries from './pages/Story/Summaries';
import Characters from './pages/Story/Characters';
import Sheets from './pages/Misc/Sheets';
import Music from './pages/Misc/Music';

function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="info" element={<Info />} />
                    <Route path="epilogue" element={<Epilogue />} />
                    <Route path="summaries" element={<Summaries />} />
                    <Route path="characters" element={<Characters />} />
                    <Route path="attributes" element={<Attributes />} />
                    <Route path="attributes/:name" element={<Attribute />} />
                    <Route path="abilities" element={<Abilities />} />
                    <Route path="abilities/:name" element={<Ability />} />
                    <Route path="auspices" element={<Auspices />} />
                    <Route path="auspices/:name" element={<Navigate to="info" replace={true}/>} />
                    <Route path="auspices/:name/*" element={<Auspice />} />
                    <Route path="gifts" element={<Gifts />} />
                    <Route path="tribes" element={<Tribes />} />
                    <Route path="tribes/:name" element={<Navigate to="info" replace={true}/>} />
                    <Route path="tribes/:name/*" element={<Tribe />} />
                    <Route path="breeds" element={<Breeds />} />
                    <Route path="breeds/:name" element={<Navigate to="info" replace={true}/>} />
                    <Route path="breeds/:name/*" element={<Breed />} />
                    <Route path="combat" element={<Combat />} />
                    <Route path="combat/stages/" element={<Navigate to="summary" replace={true} />} />
                    <Route path="combat/stages/*" element={<CombatStages />} />
                    <Route path="combat/types/" element={<Navigate to="combat_types" replace={true}/>} />
                    <Route path="combat/types/*" element={<CombatTypes />} />
                    <Route path="combat/maneuvers/" element={<Navigate to="general_maneuvers" replace={true}/>} />
                    <Route path="combat/maneuvers/*" element={<CombatManeuvers />} />
                    <Route path={"combat/arts/"} element={<Navigate to="iskakku" replace={true}/>} />
                    <Route path="combat/arts/*" element={<CombatArts />} />
                    <Route path="health" element={<Navigate to="about_health" replace={true}/>} />
                    <Route path="health/*" element={<Health />} />
                    <Route path="willpower" element={<Navigate to="willpower" replace={true}/>} />
                    <Route path="willpower/*" element={<Willpower />} />
                    <Route path="gnosis" element={<Navigate to="gnosis" replace={true}/>} />
                    <Route path="gnosis/*" element={<Gnosis />} />
                    <Route path="rage" element={<Navigate to="rage" replace={true}/>} />
                    <Route path="rage/*" element={<Rage />} />
                    <Route path="frenzy" element={<Navigate to="summary" replace={true}/>} />
                    <Route path="frenzy/*" element={<Frenzy />} />
                    <Route path="status" element={<Status />} />
                    <Route path="equipment" element={<Navigate to="melee" replace={true}/>} />
                    <Route path="equipment/*" element={<Equipment />} />
                    <Route path="sheets" element={<Sheets />} />
                    <Route path="music" element={<Music />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
//          <Route path="*" element={<NoPage />} />
export default Router;
