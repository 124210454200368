import './Combat.css';
import '../../Layout.css';
import { Link } from 'react-router-dom';

const Combat = () => {
  return (
    <section className="mainSection combatRulesSection fadeIn">
      <div className="contentContainer">
        <h1 className="wolfHeader">Combat</h1>
        <div className="combatGrid">
          <Link className='button combatButton' to="/combat/stages">Combat Stages</Link>
          <Link className='button combatButton' to="/combat/types">Combat Types</Link>
          <Link className='button combatButton' to="/combat/maneuvers">Combat Maneuvers</Link>
          <Link className='button combatButton' to="/combat/arts">Martial Arts</Link>
        </div>
      </div>
    </section>
  );
};

export default Combat;