import './Equipment.css';
import '../../Layout.css';
import '../../../Common/Animations.css'
import '../../../Common/Common.css'
import { Link } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import { Text } from "react-native";
import React, { useState, useEffect } from "react";
import { toTitleCase } from '../../../utilities/toTitleCase';
import { baseApiUrl } from '../../../utilities/baseApiUrl';
import NavigationLinker from '../../../Common/NavigationLinker';
import { getItemFromArray } from '../../../utilities/getItemFromArray';
import { getCurrentPageFromLocation } from '../../../utilities/getCurrentPage';
import { PrintDots } from '../../../utilities/dotprinter';
import { styles } from '../../../utilities/styles';

function Equipment() {
  const [data, setData] = useState([]);
  const currentPage = getCurrentPageFromLocation(useLocation());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loading) {
      loadData();
    }
  }, []);

  const loadData = async () => {
    await fetch(baseApiUrl + 'rules/equipment')
      .then(response => response.json())
      .then(data => setData(data))
      .then(setLoading(true));
  }

  console.log(data);

  function renderLowerPage(dataToCheck) {
    if (dataToCheck !== undefined) {
      return (
        <div className="fadeIn">
          <h2 className='wolfHeader'>{dataToCheck.name}</h2>
          <Text style={styles.baseText}>{dataToCheck.description}</Text>
          {renderSubSections(dataToCheck.subsections)}
        </div>
      )
    }
  }

  function renderSubSections(dataToCheck) {
    if (dataToCheck !== undefined) {
      return (
        <div className='cardGrid'>
          {dataToCheck.map((item) => (
            <div className='card'>
              <h3 className='wolfHeader'>{item.name}</h3>
              <Text style={styles.baseText}>Difficulty: {item.difficulty}</Text>
              <Text style={styles.baseText}>Rating:{item.rating}</Text>
              <Text style={styles.baseText}>Penalty: {item.penalty}</Text>
              <Text style={styles.baseText}>Damage: {item.damage}</Text>
              <Text style={styles.baseText}>Damage Type: {item.damageType}</Text>
              <Text style={styles.baseText}>Conceal: {item.conceal}</Text>
              <Text style={styles.baseText}>{item.description}</Text>
            </div>
          ))}
        </div>
      )
    }
  }

  function EquipmentNavigations() {
    return (
      <>
        <div className={`tabMenu contentTab`}>
          <NavigationLinker to="melee" name="Melee Weapons" />
          <NavigationLinker to="thrown" name="Thrown Weapons" />
          <NavigationLinker to="ranged" name="Ranged Weapons" />
          <NavigationLinker to="armor" name="Armor" />
        </div>
      </>
    )
  }

  function renderContent() {
    if (loading) {
      return (
        <div className='fadeIn'>
          <h1 className="wolfHeader">{data.name}</h1>
          <Text style={styles.baseText}>{data.description}</Text>
          <EquipmentNavigations />
          {renderLowerPage(getItemFromArray(currentPage, data.tabs, "id"))}
        </div>
      )
    }
    else {
      return (
        <div className='fadeIn'>

        </div>
      )
    }
  }

  return (
    <section className="mainSection equipmentSection fadeIn">
      <div className="contentContainer">
        {renderContent()}
      </div>
    </section>
  );
};

export default Equipment;