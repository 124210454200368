import './Auspices.css';
import '../../Layout.css';
import { useParams, useLocation } from 'react-router-dom';
import { Text } from "react-native";
import React, { useState, useEffect } from "react";
import { ArrayToString } from '../../../utilities/arrayToString';
import { baseApiUrl } from '../../../utilities/baseApiUrl';
import { styles } from '../../../utilities/styles';
import NavigationLinker from '../../../Common/NavigationLinker';
import GoBackTo from '../../../utilities/backButtonRoutes';
import { getCurrentPageFromLocation } from '../../../utilities/getCurrentPage';

function Auspice() {
    //data state // storage
    const [data, setData] = useState([]);
    const { name } = useParams();
    const currentPage = getCurrentPageFromLocation(useLocation());
    const [loading, setLoading] = useState(false);
    const logo = require('../../../static/icons/auspices/' + name + '.png');

    useEffect(() => {
        if (!loading) {
            loadData();
        }
    }, [name]);

    const loadData = async () => {
        await fetch(baseApiUrl + 'auspices/' + name)
            .then(response => response.json())
            .then(data => setData(data))
            .then(setLoading(true));
    }

    function renderStartingGifts(dataToCheck) {
        if (dataToCheck !== undefined) {
            return (
                <div>
                    <h3>Beginning Gifts:</h3>
                    <Text style={styles.baseText}>
                        {ArrayToString(data.starting_gifts)}
                    </Text>
                </div>
            )
        }
    }

    function renderInfo() {
        return (
            <div>
                <div className='topContent'>
                    <div>
                        <h3 className='wolfHeader'>Initial Rage</h3>
                        <Text style={styles.baseText}>
                            {data.rage}
                        </Text>
                    </div>
                </div>
                <h3 className='wolfHeader'>Description</h3>
                <Text style={styles.baseText}>
                    {data.description}
                </Text>
            </div>)
    }

    function renderStereotype() {
        return (
            <div>
                <h3 className='wolfHeader'>Stereotype</h3>
                <Text style={styles.baseText}>
                    {data.stereotype}
                </Text>
            </div>
        )
    }

    function renderContent() {
        if (loading) {
            return (
                <div className='fadeIn'>
                    <ul className='headerArea'>
                        <li>
                            <GoBackTo />
                        </li>
                        <li>
                            <h1 className='wolfHeader'>{data.name}</h1>
                        </li>
                    </ul>
                    <h2 className='wolfHeader'>{data.title}</h2>
                    <span className='characterLogoContainer'>
                        <img className='characterLogo' src={logo} />
                    </span>
                    <Text style={styles.quoteText}>
                        {data.quote}
                    </Text>
                    <AuspiceNavigations />
                    {
                        currentPage === 'info' ? renderInfo() : ""
                    }
                    {
                        currentPage === 'gifts' ? renderStartingGifts(data.starting_gifts) : ""
                    }
                    {
                        currentPage === 'stereotype' ? renderStereotype() : ""
                    }
                </div>
            )
        }
        else {
            return (
                <div className='fadeIn'>

                </div>
            )
        }
    }

    function AuspiceNavigations() {
        return (
            <>
                <div className={`tabMenu contentTab`}>
                    <NavigationLinker to="info" name="Information" />
                    <NavigationLinker to="gifts" name="Starting Gifts" />
                    <NavigationLinker to="stereotype" name="Stereotype" />
                </div>
            </>
        )
    }

    return (
        <section className='mainSection auspicesSection'>
            <div className="contentContainer">
                {renderContent()}
            </div>
        </section>
    );
}

export default Auspice;