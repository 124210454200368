const usersName = {
        homid: "Homid",
        metis: "Metis",
        lupus: "Lupus",
        ahroun: "Ahroun",
        galliard: "Galliard",
        philodox: "Philodox",
        theurge: "Theurge",
        ragabash: "Ragabash",
        furies: "Black Furies",
        gnawers: "Bone Gnawers",
        children: "Children of Gaia",
        croatan: "Croatan",
        howlers: "White Howlers",
        bunyip: "Bunyip",
        fianna: "Fianna",
        fenris: "Get of Fenris",
        walkers: "Glass Walkers",
        talons: "Red Talons",
        lords: "Shadow Lords",
        striders: "Silent Striders",
        fangs: "Silver Fangs",
        stargazers: "Stargazers",
        uktena: "Uktena",
        wendigo: "Wendigo"
}

export function TranslateUsersToName(str) {
    let returnString = '????';

    Object.keys(usersName).map((key) => {
        if(str === key)
        {
            returnString = usersName[key];
        }
    })
    return returnString;
}