import './Gifts.css';
import '../../Layout.css';
import React, { useState, useEffect } from "react";
import { baseApiUrl } from '../../../utilities/baseApiUrl';
import { PrintDots } from '../../../utilities/dotprinter';
import { Text, StyleSheet } from "react-native";
import { styles } from '../../../utilities/styles';
import { ArrayToString } from '../../../utilities/arrayToString';
import { TranslateUsersToName } from '../../../utilities/translateUsersToName';
import { useDebounce } from '@react-hooks-library/core';

function Gifts() {

  const [data, setData] = useState([]);
  const [dict, setDict] = useState({ key: String, users: [] });
  const [inputValue, setValue] = useState('all');
  const [breedToggle, setBreed] = useState(false);
  const [auspiceToggle, setAuspice] = useState(false);
  const [tribeToggle, setTribe] = useState(false);
  const [rankToggle, setRank] = useState(false);
  const [currentSize, setSize] = useState(10);
  const debouncedText = useDebounce(inputValue, 400)

  const searchData = async () => {
    if (inputValue != '') {
      await fetch(baseApiUrl + 'search/gifts?term=' + inputValue + '&from=0&sort=test&size=' + currentSize + '&filters=' + JSON.stringify(dict))
        .then(response => response.json())
        .then(data => setData(data))
        .then(console.log(baseApiUrl + 'search/gifts?term=' + inputValue + '&from=0&sort=test&size=' + currentSize + '&filters=' + JSON.stringify(dict)));
    }
  }

  const searchDataonClick = (event) => {
    const target = event.target;
    if (target.name === 'searchbar') {
      if (target.value === '')
        setValue('all');
      else
        setValue(event.target.value);
    }
  }

  function getNamesFromUsers(result) {
    let str = '';

    {result.users.map((user) => 
      str += TranslateUsersToName(user) + ", "
    )};

    return str.slice(0, -2);
  }

  function renderSearchResults(dataToCheck) {
    if (dataToCheck !== undefined) {
      return (
        <div>
          {dataToCheck.map((result) => (
            <div className='searchResult' key={result.name + Math.random() + Math.random()}>
              <h3 className='wolfHeader'>{result.name}</h3>
              <h4 className='wolfHeader'>Rank {result.level}</h4>
              <h4 className='wolfHeader'>Users</h4>
              <Text style={styles.baseText}>
                {getNamesFromUsers(result)}
              </Text>
              <h4 className='wolfHeader'>Description</h4>
              <Text style={styles.baseText}>
                {result.description}
              </Text>
              <h4 className='wolfHeader'>System</h4>
              <Text style={styles.baseText}>
                {result.system}
              </Text>
            </div>
          ))}
        </div>
      )
    }
  }

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    let userList = dict.users;

    if (userList && userList.length != 0) {
      if (userList.includes(name)) {
        let newList = userList.filter(data => data != name);
        setDict({ ...dict, users: newList });
        console.log("Removed " + name + " from list");
      }
      else {
        let moddedList = [...userList, name];
        setDict({ ...dict, users: moddedList });
        console.log("Added " + name + " to list");
      }
    }
    else {
      setDict({ ...dict, users: [name] })
      console.log("created list users with " + name);
    }
  }

  const handleLevelChange = (event) => {
    const target = event.target;
    const name = target.name;
    let levelList = dict.level;

    if (levelList && levelList.length != 0) {
      if (levelList.includes(name)) {
        let newList = levelList.filter(data => data != name);
        setDict({ ...dict, level: newList });
        console.log("Removed " + name + " from list");
      }
      else {
        let moddedList = [...levelList, name];
        setDict({ ...dict, level: moddedList });
        console.log("Added " + name + " to list");
      }
    }
    else {
      setDict({ ...dict, level: [name] })
      console.log("created list level with " + name);
    }
  }

  function listIncludes(list, value) {
    if (list.includes(value)) {
      return true;
    }
    else
      return false;
  }

  useEffect(() => {
    searchData();
  }, [debouncedText, dict, currentSize]);


  function showBreedContent() {
    let userList = dict.users;
    if (breedToggle) {
      return (
        <div className='searchSettingContent'>
          <label className='giftLabel'>
            Homid
            <input className='giftCheckbox' defaultChecked={listIncludes(userList, "homid")} type="checkbox" name="homid" onClick={handleChange}/>
          </label>
          <label className='giftLabel'>
            Metis
            <input className='giftCheckbox' defaultChecked={listIncludes(userList, "metis")} type="checkbox" name="metis" onClick={handleChange} />
          </label>
          <label className='giftLabel'>
            Lupus
            <input className='giftCheckbox' defaultChecked={listIncludes(userList, "lupus")} type="checkbox" name="lupus" onClick={handleChange} />
          </label>
        </div>
      )
    }
    else
    return (
      <>
      </>
    )
  }

  function showAuspiceContent() {
    let userList = dict.users;
    if (auspiceToggle) {
      return (
        <div className='searchSettingContent'>
          <label className='giftLabel'>
              Ragabash
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "ragabash")} type="checkbox" name="ragabash" onClick={handleChange} />
            </label>
            <label className='giftLabel'>
              Theurge
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "theurge")} type="checkbox" name="theurge" onClick={handleChange} />
            </label>
            <label className='giftLabel'>
              Philodox
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "philodox")} type="checkbox" name="philodox" onClick={handleChange} />
            </label>
            <label className='giftLabel'>
              Galliard
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "galliard")} type="checkbox" name="galliard" onClick={handleChange} />
            </label>
            <label className='giftLabel'>
              Ahroun
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "ahroun")} type="checkbox" name="ahroun" onClick={handleChange} />
            </label>
        </div>
      )
    }
    else
    return (
      <>
      </>
    )
  }

  function showTribeContent() {
    let userList = dict.users;
    if (tribeToggle) {
      return (
        <div className='searchSettingContent'>
          <label className='giftLabel'>
              Black Furies
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "furies")} type="checkbox" name="furies" onClick={handleChange} />
            </label>
            <label className='giftLabel'>
              Bone Gnawers
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "gnawers")} type="checkbox" name="gnawers" onClick={handleChange} />
            </label>
            <label className='giftLabel'>
              Children of Gaia
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "children")} type="checkbox" name="children" onClick={handleChange} />
            </label>
            <label className='giftLabel'>
              Croatan
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "croatan")} type="checkbox" name="croatan" onClick={handleChange} />
            </label>
            <label className='giftLabel'>
              Fianna
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "fianna")} type="checkbox" name="fianna" onClick={handleChange} />
            </label>
            <label className='giftLabel'>
              Get of Fenris
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "fenris")} type="checkbox" name="fenris" onClick={handleChange} />
            </label>
            <label className='giftLabel'>
              Glass Walkers
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "walkers")} type="checkbox" name="walkers" onClick={handleChange} />
            </label>
            <label className='giftLabel'>
              Red Talons
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "talons")} type="checkbox" name="talons" onClick={handleChange} />
            </label>
            <label className='giftLabel'>
              Shadow Lords
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "lords")} type="checkbox" name="lords" onClick={handleChange} />
            </label>
            <label className='giftLabel'>
              Silent Striders
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "striders")} type="checkbox" name="striders" onClick={handleChange} />
            </label>
            <label className='giftLabel'>
              Silver Fangs
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "fangs")} type="checkbox" name="fangs" onClick={handleChange} />
            </label>
            <label className='giftLabel'>
              Stargazers
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "stargazers")} type="checkbox" name="stargazers" onClick={handleChange} />
            </label>
            <label className='giftLabel'>
              Uktena
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "uktena")} type="checkbox" name="uktena" onClick={handleChange} />
            </label>
            <label className='giftLabel'>
              Wendigo
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "wendigo")} type="checkbox" name="wendigo" onClick={handleChange} />
            </label>
            <label className='giftLabel'>
              White Howlers
              <input className='giftCheckbox' defaultChecked={listIncludes(userList, "howlers")} type="checkbox" name="howlers" onClick={handleChange} />
            </label>
        </div>
      )
    }
    else
    return (
      <>
      </>
    )
  }

  function showRankContent() {
    if(dict.level === undefined)
    {
      setDict({ ...dict, level: [] })
    }
    let levelList = dict.level;
    if (rankToggle) {
      return (
        <div className='searchSettingContent'>
          <label className='giftLabel'>
              Cliath <PrintDots amount='1' />
              <input className='giftCheckbox' defaultChecked={listIncludes(levelList, "1")} type="checkbox" name="1" onClick={handleLevelChange} />
            </label>
            <label className='giftLabel'>
              Fostern <PrintDots amount='2' />
              <input className='giftCheckbox' defaultChecked={listIncludes(levelList, "2")} type="checkbox" name="2" onClick={handleLevelChange} />
            </label >
            <label className='giftLabel'>
              Adren <PrintDots amount='3' />
              <input className='giftCheckbox' defaultChecked={listIncludes(levelList, "3")} type="checkbox" name="3" onClick={handleLevelChange} />
            </label>
            <label className='giftLabel'>
              Athro <PrintDots amount='4' />
              <input className='giftCheckbox' defaultChecked={listIncludes(levelList, "4")} type="checkbox" name="4" onClick={handleLevelChange} />
            </label>
            <label className='giftLabel'>
              Elder <PrintDots amount='5' />
              <input className='giftCheckbox' defaultChecked={listIncludes(levelList, "5")} type="checkbox" name="5" onClick={handleLevelChange} />
            </label>
            <label className='giftLabel'>
              Legend <PrintDots amount='6' />
              <input className='giftCheckbox' defaultChecked={listIncludes(levelList, "6")} type="checkbox" name="6" onClick={handleLevelChange} />
            </label>
        </div>
      )
    }
    else
    return (
      <>
      </>
    )
  }

  const checkIfTermIsInDict = (term) => {
    if(dict.users)
    {
      dict.users.map((value) => {
        console.log(value)
        if(value && value.length != 0)
        {
          if (value.includes(term))
          {
            console.log(term + " exists")
            return true;
          }
        }
      }
      )
      console.log(term + " Does not exist")
      return false;
    }
    if(dict.level)
    {
      dict.level.map((value) => {
        console.log(value)
        if(value && value.length != 0)
        {
          if (value.includes(term))
          {
            console.log(true)
            return true;
          }
        }
      }
      )
      console.log("gets hit")
      return false;
    }
  }


  return (
    <section className="mainSection giftsSection fadeIn">
      <div className="contentContainer">
        <h1 className='wolfHeader'>Gifts</h1>
        <h2 className='wolfHeader'>Search settings</h2>
        <div className='searchSettingsContainer'>
          <div className='searchSettingSubContainer'>
            <button className='button giftButton' onClick={() => setBreed(!breedToggle)}><h3 className='wolfHeader'>Breeds</h3></button>
            {showBreedContent()}
          </div>
          <div className='searchSettingSubContainer'>
            <button className='button giftButton' onClick={() => setAuspice(!auspiceToggle)}><h3 className='wolfHeader'>Auspices</h3></button>
            {showAuspiceContent()}
          </div>
          <div className='searchSettingSubContainer'>
            <button className='button giftButton' onClick={() => setTribe(!tribeToggle)}><h3 className='wolfHeader'>Tribes</h3></button>
            {showTribeContent()}
          </div>
          <div className='searchSettingSubContainer'>
            <button className='button giftButton' onClick={() => setRank(!rankToggle)}><h3 className='wolfHeader'>Ranks</h3></button>
            {showRankContent()}
          </div>
        </div>
        <input className='searchbar' placeholder="Type text here" type="text" name="searchbar" onChange={searchDataonClick}></input>
        <hr />
        {renderSearchResults(data)}
        <div className='searchSettingSubContainer'>
          <button className='button moreButton' onClick={() => setSize(currentSize + 10)}><h3 className='wolfHeader'>More</h3></button>
        </div>
      </div>
    </section>
  );
};

export default Gifts;