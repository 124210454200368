import './Story.css';
import '../Layout.css';
import React, { useState, useEffect } from "react";
import { baseApiUrl } from '../../utilities/baseApiUrl';

function Characters() {

  const [data, setData] = useState([]);
  const [dict, setDict] = useState({ key: String, rank: [] });
  const [inputValue, setValue] = useState('');

  const searchData = async () => {
    if (inputValue != '') {
      await fetch(baseApiUrl + 'search/characters?term=' + inputValue + '&from=0&sort=test&filters=' + JSON.stringify(dict))
        .then(response => response.json())
        .then(data => setData(data))
        .then(console.log(baseApiUrl + 'search/characters?term=' + inputValue + '&from=0&sort=test&filters=' + JSON.stringify(dict)));
    }
  }

  const searchDataonClick = (event) => {
    const target = event.target;
    if (target.name === 'searchbar') {
      if (target.value === '')
        setValue('all');
      else
        setValue(event.target.value);
    }
  }

  function renderSearchResults(dataToCheck) {
    if (dataToCheck !== undefined) {
      return (
        <div>
          {dataToCheck.map((result) => (
            <div key={result.name}>
              {result.name + ", "}
            </div>
          ))}
        </div>
      )
    }
  }

  const handleRankChange = (event) => {
    const target = event.target;
    const name = target.name;
    let rankList = dict.rank;

    if (rankList && rankList.length != 0) {
      if (rankList.includes(name)) {
        let newList = rankList.filter(data => data != name);
        setDict({ ...dict, rank: newList });
        console.log("Removed " + name + " from list");
      }
      else {
        let moddedList = [...rankList, name];
        setDict({ ...dict, rank: moddedList });
        console.log("Added " + name + " to list");
      }
    }
    else {
      setDict({ ...dict, rank: [name] })
      console.log("created list rank with " + name);
    }
  }

  const handleAffiliationChange = (event) => {
    const target = event.target;
    const name = target.name;
    let affiliationList = dict.affiliation;

    if (affiliationList && affiliationList.length != 0) {
      if (affiliationList.includes(name)) {
        let newList = affiliationList.filter(data => data != name);
        setDict({ ...dict, affiliation: newList });
        console.log("Removed " + name + " from list");
      }
      else {
        let moddedList = [...affiliationList, name];
        setDict({ ...dict, affiliation: moddedList });
        console.log("Added " + name + " to list");
      }
    }
    else {
      setDict({ ...dict, affiliation: [name] })
      console.log("created list affiliation with " + name);
    }
  }

  useEffect(() => {
    searchData();
  }, [inputValue, dict]);


  return (
    <section className="mainSection charactersSection fadeIn">
      <div className="contentContainer">
        <h1 className='wolfHeader'>Characters</h1>
        <input type="text" name="searchbar" onChange={searchDataonClick}></input>
        {renderSearchResults(data)}
      </div>
    </section>
  );
};

export default Characters;